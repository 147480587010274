import { AccountNotification, SpinnerIcon, TitleSection } from 'components';
import { Button } from 'components/buttons/Button';
import AccountSelect from 'components/money/AccountSelect';
import AccountsCashChartSection from 'components/money/AccountsCashChartSection';
import ConfirmPinModal from 'components/money/ConfirmPinModal';
import { ConfigService } from 'core';
import { EKYC } from 'core/constant';
import {
  F_TRANSFER_MAX_WITHDRAW_AMOUNT,
  F_TRANSFER_MIN_WITHDRAW_AMOUNT,
} from 'core/constant/storage.constant';
import { useAccount } from 'core/contexts';
import { AssetsService } from 'core/services';
import { handleErrorMessage } from 'core/utils';
import { sha256 } from 'js-sha256';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RiErrorWarningLine } from 'react-icons/ri';
import { NumericFormat } from 'react-number-format';
import { toast } from 'react-toastify';

const CashAdvancePage = () => {
  const { account, accountInfo } = useAccount();
  const [systemConfig, setSystemConfig] = useState<any>();
  const [fromAccountSelected, setFromAccountSelected] = useState<any>();
  const [isShowConfirmPinModal, setIsShowConfirmPinModal] = useState<boolean>(false);
  const [isReloadAccountCashChart, setIsReloadAccountCashChart] = useState<boolean>(false);
  const [onToAccountChange, setOnToAccountChange] = useState<boolean>(false);
  const [advanceCashBalanceAvailable, setAdvanceCashBalanceAvailable] = useState<any>();
  const [isDisablePage, setIsDisablePage] = useState<boolean>(false);

  const [amountInput, setAmountInput] = useState<any>();
  const [fee, setFee] = useState<any>(0);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const getAvailableCia = async (accountNo: any) => {
    setOnToAccountChange(true);
    const res = await AssetsService.getAvailableCia(accountNo);
    if (res?.data?.status === 'Success') {
      setAdvanceCashBalanceAvailable(res?.data?.data);
      setOnToAccountChange(false);
      if (!res?.data?.data?.avaiable_cash_in_advance_amount) {
        setIsDisablePage(true);
      } else {
        setIsDisablePage(false);
      }
    } else {
      setAdvanceCashBalanceAvailable(undefined);
      setOnToAccountChange(false);
      toast(handleErrorMessage(res?.data?.error_message) || 'Lỗi kết nối mạng, vui lòng thử lại', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
    }
  };

  const getCiaFee = async (accountId: string, amountData: any) => {
    try {
      const params = {
        accountNo: accountId,
        amount: +amountData,
      };

      const res = await AssetsService.ciaInfo(params);

      if (res?.data?.status === 'Success') {
        setFee(res?.data?.data?.cash_in_advance_interest_amount || 0);
      } else {
        toast(
          handleErrorMessage(res?.data?.error_message) || 'Lỗi kết nối mạng, vui lòng thử lại',
          {
            type: 'error',
            icon: <RiErrorWarningLine className="text-[#F04438]" />,
          }
        );
      }
    } catch (error) {
      toast('Lỗi kết nối mạng, vui lòng thử lại', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
    }
  };

  const deboucedGetCiaFee = useCallback(
    debounce(async (amountData: any) => {
      await getCiaFee(fromAccountSelected?.account_id, amountData);
    }),
    [fromAccountSelected]
  );

  useEffect(() => {
    const fetchSystemConfig = async () => {
      const data = await ConfigService.getSystemConfig();
      setSystemConfig(data);
    };

    fetchSystemConfig();

    if (!accountInfo || accountInfo?.accounts?.length < 2) {
      setIsDisablePage(true);
    }

    if (account) {
      setFromAccountSelected(account);
    } else {
      const initAccount =
        accountInfo?.accounts?.find((item: any) => {
          return item.data?.account_type_id == 'C';
        }) || accountInfo?.accounts[0];

      setFromAccountSelected(initAccount);
    }
  }, []);

  useEffect(() => {
    if (fromAccountSelected) {
      getAvailableCia(fromAccountSelected.account_id);
    }
  }, [fromAccountSelected]);

  const resetForm = () => {
    setAmountInput(0);
    setValue('amount', undefined, { shouldValidate: false });
    setFee(0);
  };

  const submitFormAdvanceCash = () => {
    setIsShowConfirmPinModal(true);
  };

  const handleAccountSelectChange = (value: any) => {
    if (amountInput) {
      setAmountInput(0);
    }
    setFromAccountSelected(value);
    setIsReloadAccountCashChart(!isReloadAccountCashChart);
    setValue('amount', undefined, { shouldValidate: false });
    setFee(0);
  };

  const handleSubmitAdvanceCash = async (data: any) => {
    try {
      const params = {
        pin: sha256(data),
        account_no: fromAccountSelected?.account_id,
        amount: parseInt(getValues('amount')),
      };

      const res = await AssetsService.createCia(params);

      if (res?.data?.status === 'Success') {
        setTimeout(() => {
          getAvailableCia(fromAccountSelected?.account_id);
          setIsReloadAccountCashChart(!isReloadAccountCashChart);
          resetForm();
        }, 300);
        return true;
      } else {
        toast(
          handleErrorMessage(res?.data?.error_message) || 'Lỗi kết nối mạng, vui lòng thử lại',
          {
            type: 'error',
            icon: <RiErrorWarningLine className="text-[#F04438]" />,
          }
        );
        return false;
      }
    } catch (error) {
      toast('Lỗi kết nối mạng, vui lòng thử lại', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
      return false;
    }
  };

  const getSystemConfig = (key: string) => {
    return systemConfig ? JSON.parse(systemConfig?.[key]) : '';
  };

  const validateWithdrawAmount = (input: string) => {
    if (input == undefined) {
      return true;
    }

    if (!input || input.trim() === '') {
      return 'Nhập số tiền cần rút';
    }

    const minConfig = getSystemConfig(F_TRANSFER_MIN_WITHDRAW_AMOUNT) || 0;
    const maxConfig = getSystemConfig(F_TRANSFER_MAX_WITHDRAW_AMOUNT) || 0;

    if (parseInt(input) < minConfig) {
      return `Số tiền cần nhập tối thiểu là ${minConfig?.toLocaleString('en-US')}đ`;
    }

    let max = 0;
    let max_mes = '';

    if (maxConfig > (advanceCashBalanceAvailable?.avaiable_cash_in_advance_amount || 0)) {
      max = advanceCashBalanceAvailable?.avaiable_cash_in_advance_amount || 0;
      max_mes = 'Số tiền muốn rút vượt quá số dư khả dụng';
    } else {
      max = maxConfig;
      max_mes = `Số tiền rút tối đa là ${maxConfig?.toLocaleString('en-US')}đ`;
    }

    if (parseInt(input) > max) {
      return max_mes;
    }

    return true;
  };

  const onAmountChange = (value: any) => {
    if (!+value?.value) {
      return;
    }
    const inputValue = value?.value;
    setAmountInput(inputValue);
    setValue('amount', inputValue, { shouldValidate: true });
    const minConfig = getSystemConfig(F_TRANSFER_MIN_WITHDRAW_AMOUNT) || 0;
    if (+inputValue >= minConfig) {
      deboucedGetCiaFee(inputValue);
    }
  };

  return (
    <>
      <div className="flex p-2 gap-2">
        <div className="w-[62%] bg-black rounded-lg border-style !border-t-0 h-auto min-h-[60vh]">
          <TitleSection title="Ứng tiền" />
          {account ? (
            <form
              onSubmit={handleSubmit(submitFormAdvanceCash)}
              autoComplete="off"
              className="h-[calc(100%-40px)]"
            >
              <div className="flex flex-col px-8 py-4 h-full">
                <div className="flex flex-col gap-4 flex-1">
                  <div className="flex justify-between">
                    <div className="form-input w-[276px]">
                      <label htmlFor="" className="form-input__label">
                        Chọn tiểu khoản muốn ứng tiền
                      </label>
                      <AccountSelect
                        buttonContainerClass="w-full"
                        itemContainerClass="w-full"
                        handleAccountSelectChange={handleAccountSelectChange}
                        accountSelected={fromAccountSelected}
                        isDisablePage={false}
                      />
                    </div>
                    <div className=" min-w-[160px] text-end">
                      <label htmlFor="" className="form-input__label w-full">
                        Số tiền có thể ứng
                      </label>
                      {!onToAccountChange && (
                        <div className="mt-[13px] text-[20px] font-semibold leading-[26px] text-end">
                          {`${
                            advanceCashBalanceAvailable?.avaiable_cash_in_advance_amount
                              ? advanceCashBalanceAvailable?.avaiable_cash_in_advance_amount?.toLocaleString(
                                  'en-US'
                                )
                              : 0
                          }đ`}
                        </div>
                      )}
                      {onToAccountChange && (
                        <div className="mt-[10px] flex justify-center">
                          <SpinnerIcon size="small" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-input">
                    <label htmlFor="" className="form-input__label">
                      Số tiền muốn ứng
                    </label>
                    <NumericFormat
                      className="form-input__input form-input__input-editable"
                      placeholder={'Nhập số tiền muốn ứng'}
                      onValueChange={onAmountChange}
                      thousandSeparator={true}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      decimalScale={0}
                      value={amountInput}
                      disabled={isDisablePage}
                    />
                    <input
                      type="hidden"
                      {...register('amount', {
                        required: 'Nhập số tiền cần ứng',
                        validate: validateWithdrawAmount,
                      })}
                    />
                    {errors?.amount && (
                      <p className="form-errors mt-1">{errors.amount.message as string}</p>
                    )}
                  </div>
                  <div className="flex-1 mb-5">
                    <table className="w-full h-full text-sm font-normal leading-5">
                      <thead className="w-full text-text_main_light">
                        <tr className="w-full flex justify-between py-2">
                          <th className="min-w-[20%] text-start">Ngày tiền về</th>
                          <th className="flex-1 text-end">Số tiền bán</th>
                          <th className="min-w-[30%]"></th>
                          <th className="min-w-[20%] text-end">Số tiền có thể ứng</th>
                        </tr>
                      </thead>
                      <tbody className=" text-white block h-full overflow-auto mini-scrollbar">
                        {advanceCashBalanceAvailable?.items?.map((item: any, index: number) => (
                          <tr
                            key={`advanceCashBalanceAvailable_item_${index}`}
                            className="w-full flex justify-between py-2"
                          >
                            <td className="min-w-[20%] text-start">
                              <span>
                                {dayjs(new Date(item.cash_settle_date)).format('DD/MM/YYYY')}
                              </span>
                            </td>
                            <td className="flex-1 text-end">
                              <span>{item.outstanding_settle_amount?.toLocaleString('en-US')}</span>
                            </td>
                            <td className="min-w-[30%]"></td>
                            <td
                              className={`min-w-[20%] text-end ${
                                item.avaiable_cash_in_advance_amount == 0
                                  ? 'text-mustardYellow'
                                  : 'text-vividMalachite'
                              }`}
                            >
                              <span>{`+${item.avaiable_cash_in_advance_amount?.toLocaleString(
                                'en-US'
                              )}`}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="">
                    <label htmlFor="" className="form-input__label">
                      Phí ứng tiền
                    </label>
                    <div className="mt-1 text-sm font-semibold leading-5">{`${fee?.toLocaleString(
                      'en-US'
                    )}đ`}</div>
                  </div>
                  <div className="">
                    <Button
                      type={'submit'}
                      className="flex-1 bg-primary font-semibold text-white w-[247px] h-[44px] max-h-[44px]"
                      disabled={isDisablePage}
                      onClick={handleSubmit(submitFormAdvanceCash)}
                    >
                      Xác nhận
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div className="flex justify-center items-center h-full">
              <AccountNotification />
            </div>
          )}
        </div>
        <div className="w-[38%]">
          <AccountsCashChartSection isReload={isReloadAccountCashChart} />
        </div>
      </div>
      <ConfirmPinModal
        isShowModal={isShowConfirmPinModal}
        setIsShowModal={setIsShowConfirmPinModal}
        handleSubmit={handleSubmitAdvanceCash}
        successTitle="Ứng tiền thành công"
        successSubTitle="Yêu cầu ứng tiền của bạn đã được thực hiện thành công. Bạn có thể theo dõi trạng thái ứng tiền trong “Sao kê tiền”"
      />
    </>
  );
};

export default CashAdvancePage;
