import React, { useEffect, useState } from 'react';
import { Button } from 'components/buttons/Button';
import AccountsCashChartSection from 'components/money/AccountsCashChartSection';
import { useAccount } from 'core/contexts';
import { getAccountName, handleErrorMessage } from 'core/utils';
import { NumericFormat } from 'react-number-format';
import { RiErrorWarningLine } from 'react-icons/ri';
import { WalletCashBalReponseData, WalletService } from 'core/services/wallet.service';
import { toast } from 'react-toastify';
import { AccountNotification, SpinnerIcon, TitleSection } from 'components';
import ConfirmPinModal from 'components/money/ConfirmPinModal';
import { useForm } from 'react-hook-form';
import { ConfigService } from 'core';
import {
  F_TRANSFER_MAX_WITHDRAW_AMOUNT,
  F_TRANSFER_MIN_WITHDRAW_AMOUNT,
} from 'core/constant/storage.constant';
import { AssetsService } from 'core/services';
import { sha256 } from 'js-sha256';
import AccountSelect from 'components/money/AccountSelect';
import { EKYC } from 'core/constant';

const TransferPage = () => {
  const { account, accountInfo } = useAccount();
  const [fromAccountSelected, setFromAccountSelected] = useState<any>();
  const [toAccountSelected, setToAccountSelected] = useState<any>();
  const [withdrawBalance, setWithdrawBalance] = useState<WalletCashBalReponseData>();
  const [onToAccountChange, setOnToAccountChange] = useState<boolean>(false);
  const [isShowConfirmPinModal, setIsShowConfirmPinModal] = useState<boolean>(false);
  const [systemConfig, setSystemConfig] = useState<any>();
  const [amountInput, setAmountInput] = useState<any>();
  const [isDisablePage, setIsDisablePage] = useState<boolean>(false);

  const [isReloadAccountCashChart, setIsReloadAccountCashChart] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const getAccountCashBalance = async (accountNo: any) => {
    setOnToAccountChange(true);
    const res = await WalletService.getCashBal(accountNo);
    if (res?.data?.status === 'Success') {
      setWithdrawBalance(res?.data?.data);
      setOnToAccountChange(false);
      if (!res?.data?.data?.withdrawal_bal) {
        setIsDisablePage(true);
      } else {
        setIsDisablePage(false);
      }
    } else {
      setWithdrawBalance(undefined);
      setOnToAccountChange(false);
      toast(handleErrorMessage(res?.data?.error_message) || 'Lỗi kết nối mạng, vui lòng thử lại', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
    }
  };

  useEffect(() => {
    const fetchSystemConfig = async () => {
      const data = await ConfigService.getSystemConfig();
      setSystemConfig(data);
    };

    fetchSystemConfig();

    if (!accountInfo || accountInfo?.accounts?.length < 2) {
      setIsDisablePage(true);
    }

    if (account) {
      setFromAccountSelected(account);
      handleSetToAccountValue(account);
    } else {
      const initAccount =
        accountInfo?.accounts?.find((item: any) => {
          return item.data?.account_type_id == 'C';
        }) || accountInfo?.accounts[0];

      setFromAccountSelected(initAccount);
      handleSetToAccountValue(initAccount);
    }
  }, []);

  useEffect(() => {
    if (fromAccountSelected) {
      getAccountCashBalance(fromAccountSelected.account_id);
    }
  }, [fromAccountSelected]);

  const handleSetToAccountValue = (fromAccount: any) => {
    const toAccount = accountInfo?.accounts?.find((item: any) => {
      return item?.account_id !== fromAccount?.account_id;
    });
    setToAccountSelected(toAccount);
  };

  const handleAccountSelectChange = (value: any) => {
    if (amountInput) {
      setAmountInput(0);
    }
    setFromAccountSelected(value);
    handleSetToAccountValue(value);
    setIsReloadAccountCashChart(!isReloadAccountCashChart);
  };

  const onAmountChange = (value: any) => {
    if (!+value?.value) {
      return;
    }
    const inputValue = value?.value;
    setAmountInput(inputValue);
    setValue('amount', inputValue, { shouldValidate: true });
  };

  const handleSubmitTransfer = async (data: any) => {
    try {
      const params = {
        from_account_no: fromAccountSelected?.account_id,
        to_account_no: toAccountSelected?.account_id,
        amount: parseInt(getValues('amount')),
        pin: sha256(data),
      };

      const res = await AssetsService.cashTransferInside(params);

      if (res?.data?.status === 'Success') {
        setTimeout(() => {
          getAccountCashBalance(fromAccountSelected?.account_id);
          setIsReloadAccountCashChart(!isReloadAccountCashChart);
          setAmountInput(0);
          setValue('amount', undefined, { shouldValidate: false });
        }, 300);
        return true;
      } else {
        toast(
          handleErrorMessage(res?.data?.error_message) || 'Lỗi kết nối mạng, vui lòng thử lại',
          {
            type: 'error',
            icon: <RiErrorWarningLine className="text-[#F04438]" />,
          }
        );
        return false;
      }
    } catch (error) {
      toast('Lỗi kết nối mạng, vui lòng thử lại', {
        type: 'error',
        icon: <RiErrorWarningLine className="text-[#F04438]" />,
      });
      return false;
    }
  };

  const getSystemConfig = (key: string) => {
    return systemConfig ? JSON.parse(systemConfig?.[key]) : '';
  };

  const validateTransferAmount = (input: string) => {
    if (input == undefined) {
      return true;
    }

    if (!input || input.trim() === '') {
      return 'Vui lòng nhập số tiền muốn chuyển';
    }

    const minConfig = getSystemConfig(F_TRANSFER_MIN_WITHDRAW_AMOUNT) || 0;
    const maxConfig = getSystemConfig(F_TRANSFER_MAX_WITHDRAW_AMOUNT) || 0;

    if (parseInt(input) < minConfig) {
      return `Số tiền cần nhập tối thiểu là ${minConfig?.toLocaleString('en-US')}đ`;
    }

    let max = 0;
    let max_mes = '';

    if (maxConfig > (withdrawBalance?.withdrawal_bal || 0)) {
      max = withdrawBalance?.withdrawal_bal || 0;
      max_mes = 'Số tiền muốn chuyển vượt quá số dư khả dụng';
    } else {
      max = maxConfig;
      max_mes = `Số tiền chuyển tối đa là ${maxConfig?.toLocaleString('en-US')}đ`;
    }

    if (parseInt(input) > max) {
      return max_mes;
    }

    return true;
  };

  const submitFormTransfer = (formValues: any) => {
    setIsShowConfirmPinModal(true);
  };
  return (
    <>
      <div className="flex p-2 gap-2">
        <div className="w-[62%] bg-black rounded-lg border-style !border-t-0 h-auto min-h-[60vh]">
          <TitleSection title="Chuyển tiền" />
          {account ? (
            <form
              onSubmit={handleSubmit(submitFormTransfer)}
              autoComplete="off"
              className="h-[calc(100%-40px)]"
            >
              <div className="flex flex-col px-8 py-4 h-full">
                <div className="flex flex-col gap-4 flex-1">
                  <div className="flex justify-between">
                    <div className="form-input w-[276px]">
                      <label htmlFor="" className="form-input__label">
                        Chọn tiểu khoản chuyển tiền
                      </label>
                      <AccountSelect
                        buttonContainerClass="w-full"
                        itemContainerClass="w-full"
                        handleAccountSelectChange={handleAccountSelectChange}
                        accountSelected={fromAccountSelected}
                        isDisablePage={false}
                      />
                    </div>
                    <div className=" min-w-[160px] text-end">
                      <label htmlFor="" className="form-input__label w-full">
                        Số tiền có thể chuyển
                      </label>
                      {!onToAccountChange && (
                        <div className="mt-[13px] text-[20px] font-semibold leading-[26px] text-end">
                          {`${
                            withdrawBalance?.withdrawal_bal
                              ? withdrawBalance?.withdrawal_bal?.toLocaleString('en-US')
                              : 0
                          }đ`}
                        </div>
                      )}
                      {onToAccountChange && (
                        <div className="mt-[10px] flex justify-center">
                          <SpinnerIcon size="small" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-input">
                    <label htmlFor="" className="form-input__label">
                      Tiểu khoản nhận tiền
                    </label>
                    <input
                      className="form-input__input form-input__input-editable"
                      readOnly
                      placeholder="Tiểu khoản nhận tiền"
                      value={
                        toAccountSelected
                          ? getAccountName(toAccountSelected?.account_type_id)
                          : '--'
                      }
                    ></input>
                  </div>
                  <div className="form-input">
                    <label htmlFor="" className="form-input__label">
                      Số tiền muốn chuyển
                    </label>
                    <NumericFormat
                      className="form-input__input form-input__input-editable"
                      placeholder={'Nhập số tiền muốn chuyển'}
                      onValueChange={onAmountChange}
                      thousandSeparator={true}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      decimalScale={0}
                      value={amountInput}
                      disabled={isDisablePage}
                    />
                    <input
                      type="hidden"
                      {...register('amount', {
                        required: 'Vui lòng nhập số tiền muốn chuyển',
                        validate: validateTransferAmount,
                      })}
                    />
                    {errors?.amount && (
                      <p className="form-errors mt-1">{errors.amount.message as string}</p>
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="">
                    <label htmlFor="" className="form-input__label">
                      Phí chuyển tiền
                    </label>
                    <div className="mt-1 text-sm font-semibold leading-5">0đ</div>
                  </div>
                  <div className="">
                    <Button
                      type={'submit'}
                      className="flex-1 bg-primary font-semibold text-white w-[247px] h-[44px] max-h-[44px]"
                      disabled={isDisablePage}
                      onClick={handleSubmit(submitFormTransfer)}
                    >
                      Xác nhận
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div className="flex justify-center items-center h-full">
              <AccountNotification />
            </div>
          )}
        </div>
        <div className="w-[38%]">
          <AccountsCashChartSection isReload={isReloadAccountCashChart} />
        </div>
      </div>
      <ConfirmPinModal
        isShowModal={isShowConfirmPinModal}
        setIsShowModal={setIsShowConfirmPinModal}
        handleSubmit={handleSubmitTransfer}
        successTitle="Chuyển tiền thành công"
        successSubTitle="Yêu cầu chuyển tiền của Bạn đã được thực hiện thành công. Bạn có thể theo dõi trạng thái chuyển tiền trong “Sao kê tiền”."
      />
    </>
  );
};

export default TransferPage;
