import React, { useState, useEffect } from 'react';
import { OrderType } from 'core/types';
import { StockOrderRow, OrderModal } from 'components';
import { MarketStockService } from 'core';
import { toast } from 'react-toastify';
import { RiCheckboxCircleFill, RiErrorWarningLine } from 'react-icons/ri';
import { checkSavePin, isHidePopupConfirm } from 'core/utils';
import { useAccount } from 'core/contexts/AccountContext';

export const StockOrderTable = ({
  data,
  onRefresh,
  onUpdateOrder,
}: {
  data: OrderType[];
  onUpdateOrder: (order: OrderType) => void;
  onRefresh: () => void;
}) => {
  const { userInfo } = useAccount();
  const [orderCancel, setOrderCancel] = useState<OrderType>();
  const [isShowCancelOrder, setIsShowCancelOrder] = useState(false);

  const onCancelOrder = async (
    pin: string,
    type?: 'PIN' | 'SMART_OTP' | 'SMS_OTP',
    isCheckSavePinCode?: boolean
  ) => {
    if (pin) {
      let paramsdata: any = {
        order_no: orderCancel?.order_no,
        factor_authen_enum_type: type,
        pin: pin,
      };
      if (type === 'SMART_OTP' || type === 'SMS_OTP') {
        paramsdata.otp = pin;
        paramsdata.pin = '';
      }
      const res = await MarketStockService.cancelOrder(userInfo?.user_id || '', paramsdata);
      const data = res?.data;

      if (data?.status === 'Fail') {
        toast(data?.error_message.split('|')[1], {
          type: 'error',
          icon: <RiErrorWarningLine className="text-[#F04438]" />,
        });
        return;
      }
      if (data?.status === 'Success') {
        toast('Huỷ lệnh thành công', {
          type: 'success',
          icon: <RiCheckboxCircleFill className="text-[#00C802]" />,
        });
        if (isShowCancelOrder) {
          setIsShowCancelOrder(false);
        }
        if (isCheckSavePinCode && type === 'PIN') {
          await checkSavePin(pin);
        }
        setOrderCancel(undefined);
        onRefresh();
        return;
      }
    }
  };

  const init = async () => {
    if (orderCancel) {
      const pinSaved = await isHidePopupConfirm();
      if (pinSaved) {
        onCancelOrder(pinSaved);
        return;
      }
      setIsShowCancelOrder(true);
    } else {
      setIsShowCancelOrder(false);
    }
  };

  useEffect(() => {
    init();
  }, [orderCancel]);

  return (
    <>
      <table className="w-full order-book-table">
        <thead>
          <tr className="!font-semibold align-top">
            <th className={'font-semibold'}>
              <p>Mã</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.12px]">Thời gian</p>
            </th>
            <th className={'font-semibold'}>Lệnh</th>
            <th className={'font-semibold'}>
              <p>KL khớp</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.12px]">KL đặt</p>
            </th>
            <th className={'font-semibold'}>
              <p>Giá khớp</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.12px]">Giá đặt</p>
            </th>
            <th className={'font-semibold'}>
              <p>Tổng giá trị khớp</p>
              <p className="text-xs font-normal leading-[18px] tracking-[-0.12px]">
                Tổng giá trị đặt
              </p>
            </th>
            <th className={'font-semibold text-right !pr-[11px]'}>Trạng thái</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map((i: any) => (
            <StockOrderRow
              key={i?.order_no}
              order={i}
              onUpdate={order => onUpdateOrder(order)}
              onCancel={order => setOrderCancel(order)}
            />
          ))}
        </tbody>
      </table>
      {orderCancel && (
        <OrderModal
          isOpen={isShowCancelOrder}
          title="Huỷ lệnh"
          subTitle={'Bạn có chắc chắn muốn huỷ lệnh này không?'}
          stockInfo={{
            action: orderCancel.bid_ask_type,
            symbol: orderCancel.stock_symbol,
            price: orderCancel.order_price,
            quanlity: orderCancel.order_quantity,
          }}
          closeModal={() => setIsShowCancelOrder(false)}
          onSubmit={(pin, type) => onCancelOrder(pin, type, true)}
        />
      )}
    </>
  );
};
